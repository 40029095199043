<template>
	<div />
</template>
<script>
import Downloads from '@/util/Order';

export default {
	name: 'DownloadProduct',
	data() {
		return {
			downloads: new Downloads(),
		};
	},
	mounted() {
		const { name } = this.$route.query;
		this.download(this.$route.params.productId, name);
	},
	methods: {
		download(itemId, itemCode = 'download') {
			this.downloads.download(itemId).then((response) => {
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.download = itemCode;
				link.click();
			}).finally(() => {
				this.$router.replace({ name: 'Store' });
			});
		},
	},
};
</script>
